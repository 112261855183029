main {
  margin: auto;
  font-size: 20px;
  line-height: 1.6;
}

.hero {
  /* background-color: #201658; */
  min-height: calc(100vh);
  color: white;
  margin: 0;
}

.hero-1st-child {
  padding: 40px;
  max-width: 900px;
  width: 100%;
  margin: auto;
}

h1 {
  font-size: 4.5rem;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  margin-bottom: 1em;
}

h2 {
  font-size: 3rem;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  margin-bottom: 1em;
}

span {
  font-size: 1rem;
  font-weight: 300;
}

.text-gradient {
  background-image: linear-gradient(45deg, #00f5cf, #F9E8C9 30%, white 60%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 4.5rem;
  line-height: 1;
  font-weight: 700;
  margin-bottom: 1em;
  background-size: 400%;
  background-position: 0%;
}

.file-type {
  width: 200px;
  height: auto;
  border: 1px solid lightgrey;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 20px;
  text-align: center;
}

.file-type-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.file-type-box-group {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

.black-bg-transform {
  content: '';
  position: absolute;
  bottom: 0;
  left: -50%;
  right: -50%;
  top: 0;
  transform: rotate(-17deg) skew(-8deg);
  background: #c3feff;
  z-index: -1;
  background-image: rotate(8deg) skew(315deg)
}

.black-bg-transform2 {
  content: '';
  position: absolute;
  bottom: 0;
  left: -50%;
  right: -50%;
  top: 0;
  transform: rotate(4deg) skew(-8deg);
  background: #003233;
  z-index: -1;
  background-image: rotate(8deg) skew(315deg)
}


@media screen and (max-width: 600px) {
  .black-bg-transform {
    display: none;
  }

  .black-bg-transform2 {
    display: none;
  }

  .hero {
    background-color: #003233;
  }
}