html {
  font-family: 'Gabarito', system-ui;
  background-size: 224px;
}

code {
  font-family:
    Menlo,
    Monaco,
    Lucida Console,
    Liberation Mono,
    DejaVu Sans Mono,
    Bitstream Vera Sans Mono,
    Courier New,
    monospace;
}

body {
  margin: 0;
  overflow-x: hidden;
}